export const environment = {
  production: true,
  appUrl: '/api/',
  hubUrl: '/hub',
  i18nUrl: '',
  baseUrl: '',
  appId: 'zAppDevManager',
  defaultPage: 'HomePage',
  windowsAuthentication: false,
  semanticVersion: '1.0.2'
};